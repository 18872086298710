import { websiteAPI } from '../../shared/services/api'

const WE_GIVE_ENDPOINT = '/api/wegive/postdata'

const sendWeGiveEmail = async (data) => {
  return postRequest(WE_GIVE_ENDPOINT, data, '')
}

const postRequest = async (webServiceEndpoint, data, header) => {
  try {
    const response = await websiteAPI.post(webServiceEndpoint, data, header)
    return response.data
  } catch (e) {
    return new Error('An unexpected error occurred')
  }
}

export { WE_GIVE_ENDPOINT, sendWeGiveEmail, postRequest }
