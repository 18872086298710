export const PriortyFunds = ['75065', '75007', '75024']

export const UWPriortyFunds = [
  {
    'fundId': '31-4393712',
    'fundName': 'United Way of Central Ohio'
  },
  {
    'fundId': '31-4423899',
    'fundName': 'United Way of Delaware County'
  },
  {
    'fundId': '31-0641236',
    'fundName': 'United Way of North Central Ohio (Marion, Wyandot and Crawford Counties)'
  },
  {
    'fundId': '34-0714455',
    'fundName': 'United Way of Richland County'
  },
  {
    'fundId': '34-1022412',
    'fundName': 'United Way of Hardin County'
  },
  {
    'fundId': '31-0644804',
    'fundName': 'United Way of Fairfield County'
  },
  {
    'fundId': '31-1777036',
    'fundName': 'Pickaway County Community Foundation'
  },
  {
    'fundId': '34-0891893',
    'fundName': 'United Way of Van Wert County'
  },
  {
    'fundId': '31-6401367',
    'fundName': 'United Way of Guernsey, Monroe and Noble Counties'
  },
  {
    'fundId': '31-0718322',
    'fundName': 'Southeastern Ohio Foodbank & Kitchen of Hocking Athens Perry Community Action'
  }
]

export const Funds = [
  {
    'fundName': 'Access to Healthcare for Underserved Communities',
    'fundId': '75058'
  },
  {
    'fundName': "Alex's Sunnyside Playroom",
    'fundId': '4467'
  },
  {
    'fundName': 'Berger Hospital Cancer Care',
    'fundId': '43503'
  },
  {
    'fundName': 'Berger Hospital Greatest Need',
    'fundId': '43000'
  },
  {
    'fundName': 'Berger Hospital Hospice',
    'fundId': '43504'
  },
  {
    'fundName': 'Berger Hospital Nursing Scholarship',
    'fundId': '43500'
  },
  {
    'fundName': 'Berger Hospital Provider and Associate Well-being',
    'fundId': '43509'
  },
  {
    'fundName': "Berger Hospital Women's Health Fund",
    'fundId': '43512'
  },
  {
    'fundName': 'Bing Cancer Center',
    'fundId': '4428'
  },
  {
    'fundName': 'Blood & Marrow Transplant Program',
    'fundId': '4473'
  },
  {
    'fundName': 'Cold Capping Program',
    'fundId': '75061'
  },
  {
    'fundName': 'Doctors Hospital Cancer Program',
    'fundId': '55020'
  },
  {
    'fundName': 'Doctors Hospital Greatest Need',
    'fundId': '50001'
  },
  {
    'fundName': 'Doctors Hospital Healing Garden',
    'fundId': '55044'
  },
  {
    'fundName': 'Doctors Hospital Heart Care',
    'fundId': '55022'
  },
  {
    'fundName': 'Doctors Hospital Nursing Excellence',
    'fundId': '55002'
  },
  {
    'fundName': 'Doctors Hospital Patient Assistance',
    'fundId': '55005'
  },
  {
    'fundName': 'Doctors Hospital Provider and Associate Well-being',
    'fundId': '55049'
  },
  {
    'fundName': 'Doctors Hospital Residency Education',
    'fundId': '55019'
  },
  {
    'fundName': 'Doctors Hospital Womens Health Services',
    'fundId': '55007'
  },
  {
    'fundName': 'Dublin Cancer Care',
    'fundId': '85011'
  },
  {
    'fundName': 'Dublin Methodist Hospital Family Medicine Residency Practice Fund',
    'fundId': '85015'
  },
  {
    'fundName': 'Dublin Methodist Hospital Greatest Need',
    'fundId': '85001'
  },
  {
    'fundName': 'Dublin Methodist Hospital Heart Services',
    'fundId': '85010'
  },
  {
    'fundName': 'Dublin Methodist Hospital Nursing Excellence',
    'fundId': '85005'
  },
  {
    'fundName': 'Dublin Methodist Hospital Patient Assistance Fund',
    'fundId': '85009'
  },
  {
    'fundName': 'Dublin Methodist Hospital Provider and Associate Well-being',
    'fundId': '85022'
  },
  {
    'fundName': "Dublin Methodist Hospital Women's Health",
    'fundId': '85007'
  },
  {
    'fundName': 'Food is Health',
    'fundId': '24890'
  },
  {
    'fundName': 'Grady Memorial Hospital Cancer Care',
    'fundId': '95233'
  },
  {
    'fundName': 'Grady Memorial Hospital Cardiac Services',
    'fundId': '95247'
  },
  {
    'fundName': 'Grady Memorial Hospital Greatest Need',
    'fundId': '90105'
  },
  {
    'fundName': 'Grady Memorial Hospital Hospice ',
    'fundId': '95201'
  },
  {
    'fundName': 'Grady Memorial Hospital Nursing Excellence',
    'fundId': '95241'
  },
  {
    'fundName': 'Grady Memorial Hospital Provider and Associate Well-being',
    'fundId': '95622'
  },
  {
    'fundName': 'Grady Memorial Hospital Rehabilitation Services',
    'fundId': '95238'
  },
  {
    'fundName': "Grady Memorial Hospital Women's Health Services",
    'fundId': '95260'
  },
  {
    'fundName': 'Grant Medical Center Addiction Medicine Fellowship',
    'fundId': '24884'
  },
  {
    'fundName': 'Grant Medical Center Cancer Care',
    'fundId': '24713'
  },
  {
    'fundName': 'Grant Medical Center Emergency Department',
    'fundId': '24744'
  },
  {
    'fundName': 'Grant Medical Center Family Practice Medical Education',
    'fundId': '24708'
  },
  {
    'fundName': 'Grant Medical Center Greatest Need',
    'fundId': '14701'
  },
  {
    'fundName': 'Grant Medical Center Heart Care',
    'fundId': '24778'
  },
  {
    'fundName': 'Grant Medical Center Nursing Excellence',
    'fundId': '24723'
  },
  {
    'fundName': 'Grant Medical Center Pharmacy Research and Education',
    'fundId': '24710'
  },
  {
    'fundName': 'Grant Medical Center Provider and Associate Well-being ',
    'fundId': '24893'
  },
  {
    'fundName': 'Grant Medical Center Trauma Department',
    'fundId': '24747'
  },
  {
    'fundName': "Grant Medical Center Women's Health Services ",
    'fundId': '24773'
  },
  {
    'fundName': 'Grove City Methodist Hospital Greatest Needs',
    'fundId': '14702'
  },
  {
    'fundName': 'Hardin Memorial Hospital Greatest Needs',
    'fundId': '67001'
  },
  {
    'fundName': 'Hardin Memorial Hospital Provider and Associate Well-being',
    'fundId': '67002'
  },
  {
    'fundName': 'Hospice Bereavement Dog',
    'fundId': '65016'
  },
  {
    'fundName': 'Hospice Quality of Life - Final Wishes Fund',
    'fundId': '65009'
  },
  {
    'fundName': 'Kanesha Turner Moss Nursing Scholarship',
    'fundId': '85020'
  },
  {
    'fundName': 'Kobacker House Support (Hospice)',
    'fundId': '65020'
  },
  {
    'fundName': 'Lawson Chronic Disease Education Fund',
    'fundId': '24793'
  },
  {
    'fundName': 'LGBTQ Community Programs',
    'fundId': '75051'
  },
  {
    'fundName': 'Mammography Screening Fund for Underserved',
    'fundId': '75016'
  },
  {
    'fundName': 'Mansfield Hospital Cancer Cancer Care',
    'fundId': '41501'
  },
  {
    'fundName': 'Mansfield Hospital Greatest Need Fund',
    'fundId': '41500'
  },
  {
    'fundName': 'Mansfield Hospital Heart Services',
    'fundId': '41505'
  },
  {
    'fundName': 'Mansfield Hospice Greatest Needs',
    'fundId': '41507'
  },
  {
    'fundName': 'Mansfield Hospital Juvenile Diabetes Fund',
    'fundId': '41508'
  },
  {
    'fundName': 'Mansfield Hospital Nursing Scholarship General Fund',
    'fundId': '41510'
  },
  {
    'fundName': 'Mansfield Hospital Patient Assistance Fund',
    'fundId': '41511'
  },
  {
    'fundName': 'Mansfield Hospital Provider and Associate Well-being',
    'fundId': '41522'
  },
  {
    'fundName': "Mansfield Hospital Women's Health",
    'fundId': '41513'
  },
  {
    'fundName': 'Marion General Hospital Cancer Care',
    'fundId': '48500'
  },
  {
    'fundName': 'Marion General Hospital Greatest Need',
    'fundId': '48002'
  },
  {
    'fundName': 'Marion General Hospital Hospice',
    'fundId': '48003'
  },
  {
    'fundName': 'Marion General Hospital Patient Assistance',
    'fundId': '48006'
  },
  {
    'fundName': 'Marion General Hospital Provider and Associate Well-being',
    'fundId': '48007'
  },
  {
    'fundName': 'McConnell Heart Care at Riverside',
    'fundId': '4402'
  },
  {
    'fundName': 'McConnell Heart Health Center Programs/Research/Development',
    'fundId': '4395'
  },
  {
    'fundName': 'McConnell Heart Health Center Campus and Expansion Fund',
    'fundId': '04407'
  },
  {
    'fundName': 'Medical Education Fund for Pickerington Medical Campus',
    'fundId': '24753'
  },
  {
    'fundName': 'Medical Education Fund for Westerville Medical Campus',
    'fundId': '24752'
  },
  {
    'fundName': 'Mom and Baby Mobile Health Center',
    'fundId': '75041'
  },
  {
    'fundName': "Mothers' Milk Bank of Ohio",
    'fundId': '24780'
  },
  {
    'fundName': 'Mothers with Substance Abuse',
    'fundId': '75042'
  },
  {
    'fundName': 'Multiple Sclerosis Patient Assistance Fund',
    'fundId': '75036'
  },
  {
    'fundName': 'Neuroscience Wellness Center',
    'fundId': '75053'
  },
  {
    'fundName': 'Neuroscience Wellness Center Accessibility Fund',
    'fundId': '75105'
  },
  {
    'fundName': "O'Bleness Hospital- OhioHealth at Home and Athens Hospice",
    'fundId': '42500'
  },
  {
    'fundName': "O'Bleness Family Medicine Residency Program",
    'fundId': '42615'
  },
  {
    'fundName': "O'Bleness Hospital Cancer Care",
    'fundId': '42610'
  },
  {
    'fundName': "O'Bleness Hospital Greatest Need",
    'fundId': '42601'
  },
  {
    'fundName': "O'Bleness Hospital Healthy Moms and Babies",
    'fundId': '42608'
  },
  {
    'fundName': "O'Bleness Hospital Heart Care",
    'fundId': '42609'
  },
  {
    'fundName': "O'Bleness Hospital Nursing Excellence",
    'fundId': '42607'
  },
  {
    'fundName': 'OBleness Hospital Patient Assistance Fund',
    'fundId': '42606'
  },
  {
    'fundName': "O'Bleness Hospital Provider and Associate Well-being",
    'fundId': '42620'
  },
  {
    'fundName': 'OhioHealth Administrative Fellowship Leadership Development Fund',
    'fundId': '75055'
  },
  {
    'fundName': 'OhioHealth Ambulatory Care Management Fund',
    'fundId': '75028'
  },
  {
    'fundName': 'OhioHealth Ambulatory Neuro Rehab',
    'fundId': '4123'
  },
  {
    'fundName': 'OhioHealth Associate Emergency Assistance Fund',
    'fundId': '75007'
  },
  {
    'fundName': 'OhioHealth at Home Provider and Associate Well-being',
    'fundId': '65022'
  },
  {
    'fundName': 'OhioHealth Cancer Care',
    'fundId': '75022'
  },
  {
    'fundName': 'OhioHealth Community Outreach',
    'fundId': '24741'
  },
  {
    'fundName': 'OhioHealth Environmental Sustainability',
    'fundId': '75069'
  },
  {
    'fundName': 'OhioHealth Greatest Need',
    'fundId': '70001'
  },
  {
    'fundName': 'OhioHealth Health Equity',
    'fundId': '75065'
  },
  {
    'fundName': 'OhioHealth Hospice Bereavement Fund',
    'fundId': '65011'
  },
  {
    'fundName': 'OhioHealth Hospice Greatest Needs',
    'fundId': '65001'
  },
  {
    'fundName': 'OhioHealth Medical Education',
    'fundId': '75015'
  },
  {
    'fundName': 'OhioHealth Mission & Ministry',
    'fundId': '75002'
  },
  {
    'fundName': 'OhioHealth Neuroscience ALS General',
    'fundId': '75059'
  },
  {
    'fundName': 'OhioHealth Neuroscience Program Fund',
    'fundId': '75027'
  },
  {
    'fundName': 'OhioHealth Nursing Excellence',
    'fundId': '75024'
  },
  {
    'fundName': 'OhioHealth Pharmacy Education Fund',
    'fundId': '75124'
  },
  {
    'fundName': 'OhioHealth Sports Medicine',
    'fundId': '75018'
  },
  {
    'fundName': 'Physician Diversity Scholars Program',
    'fundId': '75029'
  },
  {
    'fundName': 'Provider and Associate Well-being',
    'fundId': '75064'
  },
  {
    'fundName': 'Riverside Methodist Hospital Emergency Services',
    'fundId': '4124'
  },
  {
    'fundName': 'Riverside Methodist Hospital Family Practice Residency',
    'fundId': '4101'
  },
  {
    'fundName': 'Riverside Methodist Hospital Greatest Need',
    'fundId': '1103'
  },
  {
    'fundName': 'Riverside Methodist Hospital Cancer Care',
    'fundId': '4410'
  },
  {
    'fundName': 'Riverside Methodist Hospital Patient Assistance',
    'fundId': '3375'
  },
  {
    'fundName': 'Riverside Methodist Hospital Provider and Associate Well-being',
    'fundId': '4477'
  },
  {
    'fundName': 'Riverside Methodist Hospital Spirit of Nursing',
    'fundId': '4153'
  },
  {
    'fundName': "Riverside Methodist Hospital Women's Health",
    'fundId': '4151'
  },
  {
    'fundName': 'Robert L. Thomas, DO Anesthesiology Education',
    'fundId': '55013'
  },
  {
    'fundName': 'Sexual Assault Response Network of Central Ohio (SARNCO)',
    'fundId': '4356'
  },
  {
    'fundName': 'Senior Health Services at Riverside',
    'fundId': '4128'
  },
  {
    'fundName': 'Shelby Cancer Care Fund',
    'fundId': '41514'
  },
  {
    'fundName': 'Shelby Hospital Greatest Need Fund',
    'fundId': '41515'
  },
  {
    'fundName': 'Tokarski Family NICU "Stories for Hope"',
    'fundId': '4476'
  },
  {
    'fundName': 'OhioHealth Vascular Institute',
    'fundId': '75001'
  },
  {
    'fundName': 'OhioHealth Vascular Institute Research',
    'fundId': '75033'
  },
  {
    'fundName': 'Mobile Primary Care Unit',
    'fundId': '75045'
  },
  {
    'fundName': 'OhioHealth Wellness Programs and Services',
    'fundId': '75050'
  },
  {
    'fundName': 'Clinical Enterprise Physician Leadership Development',
    'fundId': '75107'
  },
  {
    'fundName': 'Friends of Berger',
    'fundId': '43501'
  },
  {
    'fundName': 'Family Assistance- OhioHealth Berger ',
    'fundId': '43502'
  },
  {
    'fundName': "O'Bleness Hospital Peer Recovery",
    'fundId': '42614'
  },
  {
    'fundName': 'Grant Medical Center Medical Respite Care',
    'fundId': '24889'
  },
  {
    'fundName': 'Marion General Hospital Pastoral Support',
    'fundId': '48000'
  },
  {
    'fundName': 'Mansfield Hospital Mother/Baby Fund',
    'fundId': '41509'
  },
  {
    'fundName': 'Mansfield Hospital Trauma Services General Fund',
    'fundId': '41518'
  },
  {
    'fundName': 'North Region Mobile Mammography Fund',
    'fundId': '41521'
  },
  {
    'fundName': 'Doctors Hospital Education',
    'fundId': '55016'
  },
  {
    'fundName': 'Dublin Methodist Hospital Community Health Education',
    'fundId': '85004'
  },
  {
    'fundName': 'Dublin Methodist Hospital Compassion & Dignity Fund',
    'fundId': '85018'
  },
  {
    'fundName': 'The Bates Family Celebration Fund',
    'fundId': '85030'
  },
  {
    'fundName': 'Grady Memorial Hospital Patient Assistance Fund',
    'fundId': '95234'
  },
  {
    'fundName': 'Grady Memorial Hospital Medical Education',
    'fundId': '95258'
  },
  {
    'fundName': 'OhioHealth Biomedical Science STEM Program',
    'fundId': '24887'
  },
  {
    'fundName': 'Dublin Cold Capping Program',
    'fundId': '85021'
  },
  {
    'fundName': 'Pickerington Hospital Greatest Needs',
    'fundId': '24795'
  },
  {
    'fundName': "OhioHealth Women's Health Greatest Needs",
    'fundId': '75110'
  },
  {
    'fundName': 'OhioHealth Heart and Vascular Greatest Needs',
    'fundId': '75111'
  },
  {
    'fundName': 'Southeastern Medical Center Cancer Services',
    'fundId': '15501'
  },
  {
    'fundName': 'Southeastern Medical Center Heart Services',
    'fundId': '15505'
  },
  {
    'fundName': 'Southeastern Medical Center Patient Charity Care',
    'fundId': '15502'
  },
  {
    'fundName': 'Southeastern Medical Center Diabetes',
    'fundId': '15503'
  },
  {
    'fundName': 'Southeastern Medical Center Volunteer Services',
    'fundId': '15504'
  },
  {
    'fundName': 'Southeastern Medical Center Nursing Excellence',
    'fundId': '15506'
  },
  {
    'fundName': 'Southeastern Medical Center Greatest Needs',
    'fundId': '15001'
  },
  {
    'fundName': 'Dublin Methodist Hospital Trauma Services',
    'fundId': '85024'
  },
  {
    'fundName': 'Van Wert Health Greatest Needs',
    'fundId': '17000'
  },
  {
    'fundName': 'Southeastern Medical Center Provider and Associate Well-being',
    'fundId': '15507'
  },
  {
    'fundName': 'OPG Greatest Needs',
    'fundId': '72000'
  },
  {
    'fundName': 'Grove City Methodist Hospital Provider and Associate Well-being',
    'fundId': '14501'
  },
  {
    'fundName': 'OhioHealth Urology Fund',
    'fundId': '75116'
  },
  {
    'fundName': 'OhioHealth Orthopedics Fund',
    'fundId': '75117'
  },
  {
    'fundName': 'John F. Tugaoen, MD Memorial Endowed Fund',
    'fundId': '3762'
  },
  {
    'fundName': 'Doctors Hospital Family Medicine Residency',
    'fundId': '55041'
  },
  {
    'fundName': 'Doctors Hospital Internal Medicine Residency',
    'fundId': '55046'
  },
  {
    'fundName': 'Doctors Hospital General Surgery Residency',
    'fundId': '55047'
  },
  {
    'fundName': 'Scholarship for Professional Development(SPD)',
    'fundId': '55040'
  },
  {
    'fundName': 'OhioHealth Foundation Digestive Disease Fund',
    'fundId': '24745'
  },
  {
    'fundName': 'Doctors Hospital Orthopedic Residency',
    'fundId': '55024'
  },
  {
    'fundName': 'Doctors Hospital Ophthalmology Residency Support',
    'fundId': '55036'
  },
  {
    'fundName': 'Doctors Hospital Emergency Medicine Residency Support',
    'fundId': '55038'
  },
  {
    'fundName': 'Doctors Hospital Otolaryngology(ENT) Residency Support',
    'fundId': '55039'
  },
  {
    'fundName': 'Doctors Hospital OB / GYN Residency Support',
    'fundId': '55045'
  },
  {
    'fundName': 'Doctors Hospital Cardiology Fellowship',
    'fundId': '55048'
  },
  {
    'fundName': 'Doctors Hospital Neurosurgical Residency Support',
    'fundId': '55026'
  },
  {
    'fundName': 'Wolf Family Nursing Scholarship',
    'fundId': '24896'
  }
]
